@mixin theme-light-css-variables {
  // --------------------------------------
  //
  // Theme Identifier
  //
  // --------------------------------------
  color-scheme: light;

  // --------------------------------------
  //
  // Colors
  //
  // --------------------------------------

  // Primary
  --color-primary__regular: #1167e8;
  --color-primary__light: #eaf2fe;
  --color-primary__dark: #034284;

  // Secondary
  --color-secondary__regular: #20409a;
  --color-secondary__light: #7494ef;
  --color-secondary__dark: #00112a;

  // Success
  --color-success__regular: #18981d;
  --color-success__light: #e3f9e5;
  --color-success__dark: #05400a;

  // Warning
  --color-warning__regular: #f7c948;
  --color-warning__light: #fcf1cf;
  --color-warning__dark: #4e3b09;

  // Error
  --color-error__regular: #e12d39;
  --color-error__light: #ffeeee;
  --color-error__dark: #490311;

  // Info
  --color-info__regular: #1167e8;
  --color-info__light: #1167e8;
  --color-info__dark: #034284;

  // Landing Page
  --color-button-landing-page__regular: #1167e8;
  --color-button-landing-page__light: #eaf2fe;
  --color-button-landing-page__dark: #034284;

  // Primary
  --color-button-primary__regular: #292929;
  --color-button-primary__light: #292929;
  --color-button-primary__dark: #292929;
  --color-label-primary__regular: #292929;

  // Neutral
  --color-neutral__title-active: #292929; // 900
  --color-neutral__body: #1e293b; // 800
  --color-neutral__label: #475569; // 600
  --color-neutral__icon: #64748b; // 500
  --color-neutral__placeholder: #94a3b8; // 400
  --color-neutral__line: #cbd5e1; // 300
  --color-neutral__accent-background: #f8fafc; // 200
  --color-neutral__page-background: #ffffff; // 50
  --color-neutral__card-background: #ffffff; // 50
  --color-neutral__modal-background: #ffffff; // 50
  --color-neutral__input-background: #ffffff; // 50

  // DoorDash
  --color-dd-informational-emphasis__background: #494949;
  --color-dd-informational-emphasis__foreground: #ffffff;
  --color-dd-warning-emphasis__background: #f6e61b;
  --color-dd-warning-emphasis__foreground: #191919;
  --color-dd-negative-emphasis__background: #b71000;
  --color-dd-negative-emphasis__foreground: #ffffff;
  --color-dd-border__primary: #e7e7e7;
  --color-dd-bg-button_secondary: #e7e7e7;
  --color-dd-text_secondary: #191919;
  --color-dd-system-white: #ffffff;
  --color-dd-system-gray__50: #8b8b8b;
  --color-dd-system-green__40: #4bc275;

  // Input
  --color-input-border__selected: #191919;
  --color-input-border__error: #b71000;
  --color-input-text__error: #ff4d4f;

  // --------------------------------------
  //
  // Borders
  //
  // --------------------------------------

  // Border Width
  --border-width__regular: 1px;
  --border-width__thin: 1px;
  --border-width__thick: 3px;
  --border-width__card: 0.5px;
  --border-width__input: 1px;

  // Border Radius
  --border-radius__small: 4px;
  --border-radius__regular: 8px;
  --button__border-radius: var(--border-radius__regular);
  --input__border-radius: var(--border-radius__regular);

  // Box Shadow
  --box-shadow-input__selected: var(--color-input-border__selected) 0px 0px 0px 1px inset;
  --box-shadow-input__error: var(--color-input-border__error) 0px 0px 0px 1px inset;

  // --------------------------------------
  //
  // Spacing / Sizing
  //
  // --------------------------------------
  --font-size__label: 0.75rem;
  --font-weight__label: 700;
  --font-size__input: 1rem;
  --font-weight__input: normal;
  --line-height__input: 1.375rem;
  --padding__input: 0 0.75rem;
  --height__input: 40px;

  // --------------------------------------
  // Tabs view styling
  // --------------------------------------
  --tabs-background-color__primary: #ffffff;
  --tabs-background-color__secondary: #f8fafc;

  --tabs-text-color__primary: #292929;
  --tabs-text-color__secondary: #475569;
  --tabs-text-color__link: #1537c7;
  --tabs-text-color__white: #ffffff;

  --tabs-border-color__primary: #292929;
  --tabs-border-color__secondary: #cbd5e1;
  --tabs-border-radius__regular: 8px;
  --tabs-border-width__regular: 1px;
  --tabs-border-style__regular: solid;
}
