.StripeElement {
  &.credit-card-form {
    &.cc-number-input,
    &.expiration-date-input,
    &.cvc-input,
    &.zipcode-input {
      border: 1px solid var(--color-neutral__line);
      border-radius: var(--input__border-radius);
      box-shadow: none;
      height: 2.5rem;
      margin-bottom: 0;
      margin-top: 0;
      padding: 0.75rem;
      max-width: 100%;
    }
  }
}

.StripeElement {
  &.payment-request-button {
    margin: 0 0 1.5rem 0;
    max-width: 100%;
    padding: 0;
  }
}
